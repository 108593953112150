



























































import {
  toRefs,
  computed,
  defineComponent,
} from 'vue-demi'

// @ts-ignore
import StarRating from 'vue-star-rating'

import { RawLocation } from 'vue-router/types'

import { Routes } from '@/router'

interface Props extends Record<string, any> {
  readonly review: Record<string, any>
}

export default defineComponent<Props>({
  components: {
    StarRating,
  },

  props: {
    review: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const {
      review,
    } = toRefs(props)

    const route = computed<RawLocation>(() => ({ name: Routes.of.Workers.OVERVIEW, params: { id: review.value.id } }))

    return {
      route,
    }
  },
})

